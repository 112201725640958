function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Cards from '@components/cards';
import StreamerCard from '@components/cards/StreamerCard';
import { Section } from '@components/Section';
import { HomeFeedResultInterface, StreamerComponentInterface } from '@customTypes/index';
import { getFolloweeData } from '@functions/api';
import { useDeviceInfo } from '@hooks/react-use';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
// import { useSidebarStore } from '@stores/sideBarStore'
import { useEffect, useState } from 'react';
// import { useWindowSize } from 'react-use'
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
type Iprops = HomeFeedResultInterface;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "9b31bw",
  styles: "display:grid;grid-auto-flow:column;gap:0.25rem;overflow-x:auto;@media (min-width: 768px){gap:0.5rem;}"
} : {
  name: "e8iib7-HomePageStreamersSection",
  styles: "display:grid;grid-auto-flow:column;gap:0.25rem;overflow-x:auto;@media (min-width: 768px){gap:0.5rem;};label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "zjik7",
  styles: "display:flex"
} : {
  name: "1n7vzzt-HomePageStreamersSection",
  styles: "display:flex;label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "kuhu0n",
  styles: "border-radius:0.25rem;:hover{--tw-bg-opacity:0.2;background-color:rgb(249 250 251 / var(--tw-bg-opacity));}@media (max-width: 767px){display:none;}@media (min-width: 768px) and (max-width: 1024px){display:none;}"
} : {
  name: "whm97z-HomePageStreamersSection",
  styles: "border-radius:0.25rem;:hover{--tw-bg-opacity:0.2;background-color:rgb(249 250 251 / var(--tw-bg-opacity));}@media (max-width: 767px){display:none;}@media (min-width: 768px) and (max-width: 1024px){display:none;};label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "190xlna",
  styles: "padding-left:0.25rem;padding-right:0.25rem"
} : {
  name: "lnu23s-HomePageStreamersSection",
  styles: "padding-left:0.25rem;padding-right:0.25rem;label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "8c4qt8",
  styles: "display:flex;width:100%;justify-content:space-evenly;gap:1rem"
} : {
  name: "jqgwpy-HomePageStreamersSection",
  styles: "display:flex;width:100%;justify-content:space-evenly;gap:1rem;label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "kuhu0n",
  styles: "border-radius:0.25rem;:hover{--tw-bg-opacity:0.2;background-color:rgb(249 250 251 / var(--tw-bg-opacity));}@media (max-width: 767px){display:none;}@media (min-width: 768px) and (max-width: 1024px){display:none;}"
} : {
  name: "whm97z-HomePageStreamersSection",
  styles: "border-radius:0.25rem;:hover{--tw-bg-opacity:0.2;background-color:rgb(249 250 251 / var(--tw-bg-opacity));}@media (max-width: 767px){display:none;}@media (min-width: 768px) and (max-width: 1024px){display:none;};label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "190xlna",
  styles: "padding-left:0.25rem;padding-right:0.25rem"
} : {
  name: "lnu23s-HomePageStreamersSection",
  styles: "padding-left:0.25rem;padding-right:0.25rem;label:HomePageStreamersSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const HomePageStreamersSection = ({
  result,
  v_position,
  sectionBeforeHighlighted
}: {
  result: Iprops;
  v_position: number;
  sectionBeforeHighlighted: string;
}) => {
  // const { width } = useWindowSize()
  // const { isSideBarOpen } = useSidebarStore()
  // const [maxCount, setMaxCount] = useState(5)
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const followingData = useAppStore(state => state.followingData);
  const setFollowingData = useAppStore(state => state.setFollowingData);
  const me = useAuthStore(state => state.me);
  const TopStreamers = [...result.content];
  const [newStreamers, setNewStreamers] = useState(TopStreamers);
  const handlePrevClick = () => {
    setNewStreamers(oldValue => {
      const lastValue = oldValue[oldValue.length - 1];
      const originalStreamers = [...oldValue];
      originalStreamers.pop();
      return [lastValue, ...originalStreamers];
    });
  };
  const handleNextClick = () => {
    setNewStreamers(oldStreamers => {
      const [firstStreamer, ...restStreamers] = oldStreamers;
      return [...restStreamers, firstStreamer];
    });
  };

  // useEffect(() => {
  //   let images = Math.floor(width / 136)

  //   if (width >= 1024) {
  //     images = isSideBarOpen
  //       ? Math.floor((width - 224) / 188)
  //       : Math.floor((width - 56) / 188)
  //   }
  //   setMaxCount(images)
  // }, [isSideBarOpen, width])

  const followingUID = result.content.map(ele => {
    const {
      data
    } = (ele.component as StreamerComponentInterface);
    return data.user_uid.data;
  });
  const storedStreamers = followingData.map(ele => ele.user_uid);
  const followStreamers = followingUID.filter(ele => !storedStreamers.includes(ele)).join('&streamers=');
  useEffect(() => {
    if (result.content) {
      ;
      (async () => {
        if (me?.user_uid && followStreamers) {
          const followeeData = await getFolloweeData(followStreamers);
          const newFolloweeData = [...followingData, ...followeeData];
          setFollowingData(newFolloweeData);
        }
      })();
    }
  }, [followStreamers, me?.user_uid]);
  return _jsx(_Fragment, {
    children: _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: "/streamers",
      sectionBeforeHighlighted: sectionBeforeHighlighted,
      source_component_data: {
        source_component_name: result?.title ?? '',
        source_component_type: 'horizontal streamer',
        source_component_v_position: v_position + 1,
        //carousel will always be at first position
        source_component_h_position: 1,
        // source_component_size: maxCount,
        source_view_all_in_source_component: 'yes',
        source_ingredient_name: result?.title,
        source_ingredient_position: v_position,
        source_ingredient_size: result?.content?.length
      },
      children: _jsx("div", {
        css: _ref,
        children: _jsxs("div", {
          css: _ref2,
          children: [_jsx("button", {
            onClick: handlePrevClick,
            css: _ref3,
            children: _jsx("img", {
              src: "/CarousalArrowLeftWhite.svg",
              alt: "previous",
              css: _ref4
            })
          }), _jsx("div", {
            css: _ref5,
            children: newStreamers?.slice(0, 10).map((streamer, index) => {
              const {
                data
              } = (streamer.component as StreamerComponentInterface);
              const isFollowing = Array.from(followingData).find(ele => ele.user_uid === data.user_uid.data)?.is_following;
              return !screen_isDesktop ? _jsx(Cards, {
                cardType: "streamer",
                path: `/streamers/${data.streamer_name.data.toLocaleLowerCase()}`,
                title: data.streamer_name.data,
                thumbnail_src: data.streamer_image.data,
                count: data.followers_count.data.toString()
                // count_title="followers"
                ,
                source_component_data: {
                  source_component_name: result?.title ?? '',
                  source_component_type: 'horizontal streamer',
                  source_component_v_position: v_position + 1,
                  //carousel will always be at first position
                  source_component_h_position: 1,
                  // source_component_size: maxCount,

                  source_position_in_source_component: index + 1,
                  source_view_all_in_source_component: 'no',
                  source_ingredient_name: result?.title,
                  source_ingredient_position: v_position,
                  source_ingredient_size: result?.content?.length,
                  source_position_in_ingredient: index + 1
                }
              }, data.user_uid.data) : _jsx(StreamerCard, {
                path: `/streamers/${data.streamer_name.data.toLocaleLowerCase()}`,
                title: data.streamer_name.data,
                thumbnail_src: data.streamer_image.data,
                followers_count: data.followers_count.data,
                views_count: data.views_count?.data,
                isFollowing: isFollowing ?? false,
                user_uid: data.user_uid.data.toString(),
                isLive: data.is_live,
                user_type: data.user_type,
                verified_streamer: data.verified_streamer,
                source_component_data: {
                  source_component_name: result?.title ?? '',
                  source_component_type: 'horizontal streamer',
                  source_component_v_position: v_position + 1,
                  //carousel will always be at first position
                  source_component_h_position: 1,
                  // source_component_size: maxCount,

                  source_position_in_source_component: index + 1,
                  source_view_all_in_source_component: 'no',
                  source_ingredient_name: result?.title,
                  source_ingredient_position: v_position,
                  source_ingredient_size: result?.content?.length,
                  source_position_in_ingredient: index + 1
                }
              }, data.user_uid.data);
            })
          }), _jsx("button", {
            onClick: handleNextClick,
            css: _ref6,
            children: _jsx("img", {
              src: "CarousalArrowRightWhite.svg",
              alt: "Next",
              css: _ref7
            })
          })]
        })
      })
    })
  });
};
export default HomePageStreamersSection;