import { jsx as _jsx } from "@emotion/react/jsx-runtime";
interface StructuredDataProps {
  data: any;
}
function StructuredData(props: StructuredDataProps) {
  const {
    data
  } = props;
  return _jsx("script", {
    suppressHydrationWarning: true,
    type: "application/ld+json",
    dangerouslySetInnerHTML: {
      __html: JSON.stringify(data)
    }
  });
}
export default StructuredData;