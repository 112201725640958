function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { HighlightedSectionTitle } from './HighlightedSectionTitle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface HighlightedSectionProps {
  title?: string;
  subTitle?: string;
  subTitleLink?: string;
  thumbnail?: string;
  highlightColor?: string;
  source_component_data?: {
    [id: string]: string | number;
  };
  children: React.ReactNode;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "33xtib",
  styles: "width:100%;padding-left:14px;padding-right:14px;@media (min-width: 1024px){padding-bottom:1.5rem;}"
} : {
  name: "11fqy0a-HighlightedSection",
  styles: "width:100%;padding-left:14px;padding-right:14px;@media (min-width: 1024px){padding-bottom:1.5rem;};label:HighlightedSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const HighlightedSection = ({
  title,
  subTitle,
  subTitleLink = '',
  thumbnail = '',
  source_component_data,
  children
}: HighlightedSectionProps) => {
  return _jsxs("div", {
    css: _ref,
    style: {
      borderWidth: '1px 0px 1px 0px',
      borderStyle: 'solid',
      borderImageSource: 'linear-gradient(242.02deg, #FF0068 6.94%, #FF5A12 90.68%)',
      borderImageSlice: 1 // Added to ensure the gradient is applied correctly
    },
    children: [title || title && subTitle ? _jsx(HighlightedSectionTitle, {
      title: title,
      thumbnail: thumbnail,
      subTitle: subTitle,
      subTitleLink: (subTitleLink as string),
      source_component_data: source_component_data
    }) : null, children]
  });
};