function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FollowersIcon from '@components/atoms/svg/FollowersIcon';
import ViewsIcon from '@components/atoms/svg/ViewsIcon';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import { useAppStore } from '@stores/appStore';
import NextImage from 'next/legacy/image';
import Link from 'next/link';
import { abbreviateNumber } from '../../utils/numberFormator';
import AspectRatio from '../aspectRatio';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "5dg87w",
  styles: "height:100%;width:6rem;cursor:pointer;text-align:left;@media (min-width: 768px){width:6rem;}"
} : {
  name: "17viuzb-Cards",
  styles: "height:100%;width:6rem;cursor:pointer;text-align:left;@media (min-width: 768px){width:6rem;};label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1kzq5ms",
  styles: "height:100%;width:100%"
} : {
  name: "rcndpx-Cards",
  styles: "height:100%;width:100%;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1qkt16r",
  styles: "position:relative;height:100%;width:100%"
} : {
  name: "1122zzj-Cards",
  styles: "position:relative;height:100%;width:100%;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "oc4e1g",
  styles: "border-radius:9px"
} : {
  name: "1x5gxbv-Cards",
  styles: "border-radius:9px;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "4zosyj",
  styles: "margin-top:0.25rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;font-size:12px;font-weight:700"
} : {
  name: "bmru83-Cards",
  styles: "margin-top:0.25rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;font-size:12px;font-weight:700;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "t34fxx",
  styles: "display:flex;justify-content:center;text-align:center;font-size:12px;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity))"
} : {
  name: "1hlwmit-Cards",
  styles: "display:flex;justify-content:center;text-align:center;font-size:12px;--tw-text-opacity:1;color:rgb(178 178 178 / var(--tw-text-opacity));label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "qqykjf",
  styles: "margin-right:0.25rem"
} : {
  name: "196rdxn-Cards",
  styles: "margin-right:0.25rem;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "qqykjf",
  styles: "margin-right:0.25rem"
} : {
  name: "196rdxn-Cards",
  styles: "margin-right:0.25rem;label:Cards;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
function Cards({
  path,
  thumbnail_src,
  title,
  count,
  count_title,
  source_component_data,
  cardType
}: {
  path: string;
  thumbnail_src: string;
  title: string;
  count: string;
  count_title?: string;
  source_component_data?: {
    [id: string]: string | number;
  };
  cardType?: string;
}) {
  const {
    sourceTrackingInfo,
    setSourceTrackingInfo
  } = useAppStore(state => state);
  const handleSubtitleClick = () => {
    setSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
  };
  if (!thumbnail_src) return null;
  return _jsx(Link, {
    passHref: true,
    href: path,
    prefetch: false,
    legacyBehavior: true,
    children: _jsx("a", {
      onClick: handleSubtitleClick,
      role: "presentation",
      children: _jsx("div", {
        css: _ref,
        children: _jsxs("div", {
          css: _ref2,
          children: [_jsx(AspectRatio, {
            ratio: 1 / 1,
            children: _jsx("div", {
              css: _ref3,
              children: _jsx(NextImage, {
                src: thumbnail_src,
                alt: path.startsWith('/categories') ? `${converCategoryIdIntoUsername(title)} Game Category - Loco` : path.startsWith('/streamers') ? `${title} Streamer on Loco` : 'homefeed populer category section',
                css: _ref4,
                objectFit: "contain",
                layout: "fill",
                quality: "100"
              })
            })
          }), _jsx("div", {
            css: _ref5,
            children: title
          }), _jsxs("div", {
            css: _ref6,
            children: [cardType === 'streamer' ? _jsx(FollowersIcon, {
              css: _ref7,
              width: 16,
              height: 16
            }) : _jsx(ViewsIcon, {
              css: _ref8,
              width: 16,
              height: 16
            }), abbreviateNumber(parseInt(count, 10)), " ", count_title]
          })]
        })
      })
    })
  });
}
export default Cards;