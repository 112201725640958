import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1gcb1uo",
  styles: "position:relative;display:grid;min-height:calc(100vh - 250px);width:100%;grid-template-columns:repeat(1, minmax(0, 1fr));gap:1.5rem;overflow:hidden;@media (min-width: 768px){gap:1.5rem;}@media (min-width: 1024px){padding-left:1.5rem;padding-right:1.5rem;padding-top:1rem;}@media (max-width: 767px){padding:1rem;}@media (min-width: 768px) and (max-width: 1024px){padding:0.5rem;}"
} : {
  name: "rydnpj-SectionWrapper",
  styles: "position:relative;display:grid;min-height:calc(100vh - 250px);width:100%;grid-template-columns:repeat(1, minmax(0, 1fr));gap:1.5rem;overflow:hidden;@media (min-width: 768px){gap:1.5rem;}@media (min-width: 1024px){padding-left:1.5rem;padding-right:1.5rem;padding-top:1rem;}@media (max-width: 767px){padding:1rem;}@media (min-width: 768px) and (max-width: 1024px){padding:0.5rem;};label:SectionWrapper;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const SectionWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return _jsx("div", {
    css: _ref,
    children: children
  });
};