function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import StreamerCountsSection from '@components/atoms/Components/StreamerCountsSection';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import { useDeviceInfo } from '@hooks/react-use';
import { useAppStore } from '@stores/appStore';
import NextImage from 'next/legacy/image';
import Link from 'next/link';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "77w8uv-CategoryCard",
  styles: "display:flex;align-items:center;justify-content:center;label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "155wo3f",
  styles: "margin-left:auto;margin-right:auto;display:flex;flex-direction:column;align-items:center;overflow:hidden;padding-left:0.5rem;padding-right:0.5rem;padding-top:0.5rem;padding-bottom:0.75rem;@media (min-width: 768px){:hover{border-radius:20px;--tw-bg-opacity:1;background-color:rgb(33 33 33 / var(--tw-bg-opacity));}}"
} : {
  name: "ukp13y-CategoryCard",
  styles: "margin-left:auto;margin-right:auto;display:flex;flex-direction:column;align-items:center;overflow:hidden;padding-left:0.5rem;padding-right:0.5rem;padding-top:0.5rem;padding-bottom:0.75rem;@media (min-width: 768px){:hover{border-radius:20px;--tw-bg-opacity:1;background-color:rgb(33 33 33 / var(--tw-bg-opacity));}};label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "18bp9dn",
  styles: "position:relative;height:96px;width:96px;overflow:hidden;border-radius:17.5px;--tw-bg-opacity:1;background-color:rgb(32 32 32 / var(--tw-bg-opacity));@media (min-width: 768px){height:176px;width:176px;border-radius:9px;}"
} : {
  name: "52txpc-CategoryCard",
  styles: "position:relative;height:96px;width:96px;overflow:hidden;border-radius:17.5px;--tw-bg-opacity:1;background-color:rgb(32 32 32 / var(--tw-bg-opacity));@media (min-width: 768px){height:176px;width:176px;border-radius:9px;};label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1yz97uj",
  styles: "margin-top:8px;width:100%;max-width:94px;overflow:hidden;@media (min-width: 768px){max-width:120px;}"
} : {
  name: "17f9fqn-CategoryCard",
  styles: "margin-top:8px;width:100%;max-width:94px;overflow:hidden;@media (min-width: 768px){max-width:120px;};label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1vvo3uy",
  styles: "margin-top:0.25rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;font-size:14px;font-weight:700;line-height:20.75px;@media (min-width: 768px){font-size:16px;}"
} : {
  name: "k52js-CategoryCard",
  styles: "margin-top:0.25rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;font-size:14px;font-weight:700;line-height:20.75px;@media (min-width: 768px){font-size:16px;};label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "73xti6",
  styles: "margin-top:8px;width:100%;padding-left:0.5rem;padding-right:0.5rem"
} : {
  name: "9915ie-CategoryCard",
  styles: "margin-top:8px;width:100%;padding-left:0.5rem;padding-right:0.5rem;label:CategoryCard;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const CategoryCard = ({
  path,
  thumbnail_src,
  title,
  followers_count,
  views_count,
  source_component_data
}: {
  path: string;
  thumbnail_src: string;
  title: string;
  followers_count: number;
  views_count: number;
  source_component_data?: {
    [id: string]: string | number;
  };
}): JSX.Element => {
  const {
    sourceTrackingInfo,
    setSourceTrackingInfo
  } = useAppStore(state => state);
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const handleClick = () => {
    setSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
  };
  return _jsx(Link, {
    passHref: true,
    href: path,
    prefetch: false,
    legacyBehavior: true,
    children: _jsx("a", {
      onClick: handleClick,
      role: "presentation",
      children: _jsx("div", {
        css: _ref,
        children: _jsxs("div", {
          css: _ref2,
          children: [_jsx("div", {
            css: _ref3,
            children: _jsx(NextImage, {
              alt: `${converCategoryIdIntoUsername(title)} Game Category - Loco`,
              src: thumbnail_src,
              objectFit: "cover",
              quality: "50",
              height: screen_isDesktop ? 176 : 96,
              width: screen_isDesktop ? 176 : 96
            })
          }), _jsx("div", {
            css: _ref4,
            children: _jsx("h3", {
              css: _ref5,
              children: title
            })
          }), _jsx("div", {
            css: _ref6,
            children: _jsx(StreamerCountsSection, {
              isHideFollowers: "mweb",
              followers_count: followers_count,
              views_count: views_count
            })
          })]
        })
      })
    })
  });
};
export default CategoryCard;