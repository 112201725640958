function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Cards from '@components/cards';
import CategoryCard from '@components/cards/CategoryCard';
import { HighlightedSection } from '@components/HighlightedSection';
import { Section } from '@components/Section';
import { CategoryComponentInterface, HomeFeedResultInterface } from '@customTypes/index';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import { useDeviceInfo } from '@hooks/react-use';
// import { useSidebarStore } from '@stores/sideBarStore'
// import { useEffect, useState } from 'react'
// import { useWindowSize } from 'react-use'
// import { useTranslation } from 'src/i18n'
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
type Iprops = HomeFeedResultInterface;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1cglwt8",
  styles: "display:grid;grid-auto-flow:column;justify-content:space-between;gap:1rem;overflow-x:auto;@media (min-width: 768px){gap:1.5rem;}"
} : {
  name: "16o4zpn-CommonComponent",
  styles: "display:grid;grid-auto-flow:column;justify-content:space-between;gap:1rem;overflow-x:auto;@media (min-width: 768px){gap:1.5rem;};label:CommonComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const HomePageCategorySection = ({
  result,
  v_position,
  sectionBeforeHighlighted
}: {
  result: Iprops;
  v_position: number;
  sectionBeforeHighlighted: string;
}) => {
  // const { t } = useTranslation()
  // const { width } = useWindowSize()
  // const { isSideBarOpen } = useSidebarStore()
  const {
    screen_isDesktop
  } = useDeviceInfo();
  // const [maxCount, setMaxCount] = useState(5)

  // useEffect(() => {
  //   let images = Math.floor(width / 136)
  //   if (width > 1024) {
  //     images = isSideBarOpen
  //       ? Math.floor((width - 224) / 186)
  //       : Math.floor((width - 56) / 186)
  //   }
  //   // setMaxCount(images)
  // }, [isSideBarOpen, width])

  const sourceComponentData = {
    source_component_name: result?.title ?? '',
    source_component_type: 'horizontal category',
    source_component_v_position: v_position + 1,
    source_component_h_position: 1,
    // source_component_size: maxCount,
    source_ingredient_name: result?.title,
    source_ingredient_position: v_position,
    source_ingredient_size: result?.content?.length
  };
  const CommonComponent = ({
    ComponentType
  }: {
    ComponentType: any;
  }) => _jsx("div", {
    css: _ref,
    children: result.content?.slice(0, 10).map((game, index) => {
      const {
        data
      } = (game.component as CategoryComponentInterface);
      return _jsx(ComponentType, {
        path: `/categories/${converCategoryIdIntoUsername(data.game_uid.data)}`,
        title: data.title.data,
        followers_count: +data.followers_count?.data,
        views_count: +data.views_count.data,
        count: data.views_count.data
        // count_title={' ' + t('profile_page.following.views')}
        ,
        thumbnail_src: data.thumbnail.data,
        source_component_data: {
          ...sourceComponentData,
          source_position_in_source_component: index + 1,
          source_view_all_in_source_component: ComponentType === CategoryCard ? 'no' : 'yes',
          source_position_in_ingredient: index + 1
        }
      }, data.game_uid.data);
    })
  });
  if (!screen_isDesktop) {
    return _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: "/categories",
      sectionBeforeHighlighted: sectionBeforeHighlighted,
      source_component_data: {
        ...sourceComponentData,
        source_view_all_in_source_component: 'yes'
      },
      children: _jsx(CommonComponent, {
        ComponentType: Cards
      })
    });
  }
  return _jsx(_Fragment, {
    children: result.highlightColor ? _jsx(HighlightedSection, {
      title: result.title,
      thumbnail: result.thumbnail,
      highlightColor: result.highlightColor,
      subTitle: "See More",
      subTitleLink: "/categories",
      source_component_data: {
        ...sourceComponentData,
        source_view_all_in_source_component: 'yes'
      },
      children: _jsx(CommonComponent, {
        ComponentType: CategoryCard
      })
    }) : _jsx(Section, {
      title: result.title,
      subTitle: "See More",
      subTitleLink: "/categories",
      sectionBeforeHighlighted: sectionBeforeHighlighted,
      source_component_data: {
        ...sourceComponentData,
        source_view_all_in_source_component: 'yes'
      },
      children: _jsx(CommonComponent, {
        ComponentType: CategoryCard
      })
    })
  });
};
export default HomePageCategorySection;