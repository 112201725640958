import { NextSeo, NextSeoProps } from 'next-seo';
import React from 'react';
import StructuredData from './StructuredData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface SeoProps extends NextSeoProps {
  data?: any;
}
export default function Seo(props: SeoProps) {
  const {
    data,
    ...rest
  } = props;
  return _jsxs(_Fragment, {
    children: [_jsx(NextSeo, {
      ...rest
    }), data && _jsx(StructuredData, {
      data: data
    })]
  });
}